import { getBackendSrv, getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { Icon, Spinner, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { DashboardProps, ErrorTypes, Process, CurrentStep } from 'app-context/types';
import { GetDashboardModel } from 'dashboards_json/DashboardModel';
import { css } from '@emotion/css';
import {
  ANALYSIS_PROFILE_TABLE,
  FIELDS_JSON_IDCARD,
  INCIDENT_ADVISOR_TXT,
  MACHINE_TAG,
  SENSOR_ORIENTATION_TABLE,
  STATUS_INVALIDATED,
} from 'helpers/constants';
import {
  getOrientationValues,
  searchAndFillPanelIdCard,
  updateLengthUnitPanels,
  updateTemperatureUnitPanels,
} from 'helpers/helpersDashboards';
import {
  findIndexInForm,
  getDashboardJsonInfos,
  getUpdatedTemplatingList,
  injectThresholdInAlertPanel,
  // isCreatableField,
  setError,
} from 'helpers/helpersForm';
import { RED } from 'helpers/helpersStyle';
import { API_DASHBOARD_BY_UID, API_SAVE_JSON, DASHBOARD_JSON_MODEL, DASHBOARD_VALVE_JSON_MODEL } from 'helpers/URLS';
import React, { useEffect, useState } from 'react';
import { RestartProcessButton } from 'shared/components/RestartProcessButton';
import { FinalMessages } from './FinalMessages';
import { mysqlRequest } from 'services/requests';
import { NotificationError } from 'shared/components/NotificationMessage';
import { preparePayloadAndIdElements, sendImage } from 'helpers/utils';

/**
 * Validation form step, update / create dashboard.
 * - Save new dashboard in Grafana and grafana_asystom db.
 * - Post image and save it on server.
 * - Display final success or failure message.
 */

export const Validation: React.FunctionComponent = () => {
  const { state, dispatch } = useAppState();
  const {
    currentModal,
    customIso,
    dashboardFolder,
    datasourceMysql,
    datasourceJson,
    dictionary_uiElements,
    error,
    fileImage,
    fileImageName,
    form,
    isoClassifications,
    lang,
    lengthUnit,
    multiLingualDictionary,
    temperatureUnit,
    uid,
  } = state;
  /**
   * Translations
   */
  const {
    TR_aliasAmbientTemperature,
    TR_ambientTemperature,
    TR_analysisProfileField,
    TR_errorConnectDb,
    TR_errorDashboardModel,
    TR_errorRequestDb,
    TR_errorSaveImage,
    TR_errorSavingDashboard,
    TR_finalButtonCreate,
    TR_finalButtonGo,
    TR_finalButtonUpdate,
    TR_finalMessageCreate,
    TR_finalMessageCreate2,
    TR_finalMessageUpdate,
    TR_finalMessageUpdate2,
    TR_loadingTextCreate,
    TR_loadingTextUpdate,
    TR_sensorOrientationField,
    TR_surfaceTemperature,
    TR_tagsField,
    TR_valve,
    TR_vibratorySeverity,
  } = dictionary_uiElements;

  const [newDashboardUrl, setNewDashboardUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');

  const styles = useStyles2(getStyles);
  const orientationValues = getOrientationValues(form);
  const indexTags = findIndexInForm(TR_tagsField, form);
  const formProfileIndex = findIndexInForm(TR_analysisProfileField, form);
  const formOrientationIndex = findIndexInForm(TR_sensorOrientationField, form);

  const searchMachineTag = (tag: { value: string }) => tag.value === MACHINE_TAG;

  let dashboardInfos = getDashboardJsonInfos(indexTags, orientationValues, searchMachineTag, state, customIso);

  // endpoints
  const WRITE_API_URL = `api/datasources/proxy/uid/${datasourceJson.uid}`;
  const IDENTITY_CARD_ENDPOINT = `${WRITE_API_URL}/identity_card`;
  const AI_SESSION_STATUS_ENDPOINT = `${WRITE_API_URL}/ai_session_status`;

  const handleError = (errorName: ErrorTypes, errorText: string) => {
    setError(errorName, errorText, dispatch);
    setIsLoading(false);
    return;
  };

  /** Send json to Grafana (creation) */
  const sendNewDashboardToGrafana = async () => {
    let analysisProfile = '';

    if (formProfileIndex !== -1) {
      analysisProfile = form[formProfileIndex].value;
    }

    const dashboardJSON = await GetDashboardModel(
      dashboardInfos,
      dictionary_uiElements,
      temperatureUnit,
      lengthUnit,
      analysisProfile,
      isoClassifications
    ).catch((err) => {
      console.log(err);
      handleError(ErrorTypes.badRequest, TR_errorDashboardModel);
      return;
    });

    if (!dashboardJSON) {
      handleError(ErrorTypes.badRequest, TR_errorDashboardModel);
      return;
    }

    dashboardJSON.folderId = dashboardFolder.id;

    await getBackendSrv()
      .post(API_SAVE_JSON, dashboardJSON)
      .then((result) => setNewDashboardUrl(result?.url))
      .catch((e: any) => {
        handleError(ErrorTypes.badRequest, TR_errorSavingDashboard);
        console.log(e);
      });
  };

  /**
   * UPDATE DASHBOARD (in dB and in Grafana)
   */

  const updateDashboard = async () => {
    setLoadingText(TR_loadingTextUpdate);
    let dashboardToUpdate: DashboardProps;

    /** Retrieve the Grafana dashboard to update */
    let jsonToUpdate: any = await getBackendSrv()
      .get(`${API_DASHBOARD_BY_UID}${uid}`)
      .catch(() => {
        handleError(ErrorTypes.badRequest, TR_errorConnectDb);
      });

    // Set updated dashboard url, used at last in the "Go to dashboard" button
    setNewDashboardUrl(jsonToUpdate?.meta?.url);
    let folderId = jsonToUpdate?.meta?.folderId;

    // check if dashboard template needs to be reset (valve profile vs other profiles)
    let oldAnalysisProfile = '';

    // Detect changement of anomaly detector parameters (analysis_profile and sensor_orientation)
    let profileHasChanged = false;
    let orientationHasChanged = false;

    // Search id card elements in Incident Advisor plugin
    const advisorIndex = jsonToUpdate.dashboard.panels.findIndex(
      (panel: { type: string }) => panel.type === INCIDENT_ADVISOR_TXT
    );

    if (advisorIndex !== -1) {
      // Get old analysis_profile
      const indexProfile = jsonToUpdate.dashboard.panels[advisorIndex].idCardElements.findIndex(
        (element: { field: string }) => element.field === ANALYSIS_PROFILE_TABLE
      );

      if (indexProfile !== -1 && formProfileIndex !== -1) {
        oldAnalysisProfile = jsonToUpdate.dashboard.panels[advisorIndex].idCardElements[indexProfile].value;
        const profile = form[formProfileIndex].value;
        profileHasChanged = oldAnalysisProfile !== profile;

        if (oldAnalysisProfile !== profile && (oldAnalysisProfile === TR_valve || profile === TR_valve)) {
          let newProfileTemplate = {} as any;

          await getBackendSrv()
            .get(
              `${profile === dictionary_uiElements.TR_valve ? DASHBOARD_VALVE_JSON_MODEL : DASHBOARD_JSON_MODEL}${
                dashboardInfos.lang
              }.json`
            )
            .then((response) => {
              newProfileTemplate = response;
            });

          newProfileTemplate.dashboard.uid = jsonToUpdate.dashboard.uid;
          newProfileTemplate.dashboard.title = jsonToUpdate.dashboard.title;
          newProfileTemplate.dashboard.version = jsonToUpdate.dashboard.version;
          newProfileTemplate.dashboard.time = jsonToUpdate.dashboard.time;
          newProfileTemplate.dashboard.timezone = jsonToUpdate.dashboard.timezone;
          newProfileTemplate.dashboard.id = jsonToUpdate.dashboard.id;
          newProfileTemplate.dashboard.iteration = jsonToUpdate.dashboard.iteration;
          newProfileTemplate.dashboard.panels[advisorIndex].options =
            jsonToUpdate.dashboard.panels[advisorIndex].options;
          newProfileTemplate.dashboard.tags = jsonToUpdate.dashboard.tags;
          newProfileTemplate.dashboard.schemaVersion = jsonToUpdate.dashboard.schemaVersion;
          newProfileTemplate.dashboard.style = jsonToUpdate.dashboard.style;
          newProfileTemplate.dashboard.refresh = jsonToUpdate.dashboard.refresh;
          newProfileTemplate.dashboard.templating = jsonToUpdate.dashboard.templating;
          // inject datasource in the templates variables (needed for session_id)
          newProfileTemplate.dashboard.templating?.list.map(
            (template: { datasource: { type: string; uid: string } }) => {
              if (template?.datasource) {
                if (template.datasource.type === 'mysql') {
                  template.datasource.uid = dashboardInfos.mysql.uid;
                }
              }
            }
          );

          // Inject datasource in the dashboard (profile changed)
          for (const panel of newProfileTemplate.dashboard.panels) {
            // inject datasources
            if (panel?.datasource?.type === 'mysql') {
              panel.datasource.uid = dashboardInfos.mysql.uid;
            }
            if (panel?.datasource?.type === 'influxdb') {
              panel.datasource.uid = dashboardInfos.influx.uid;
            }
            // inject beacon
            if (panel?.targets?.length) {
              for (const panelTarget of panel.targets) {
                // Replace '$beacon_selection' by the current mac address
                if (panelTarget?.query) {
                  /** Inject datasources */
                  panelTarget.query = panelTarget.query.replace('$beacon_selection', dashboardInfos.beacon);
                }
              }
            }

            if (panel.panels?.length) {
              for (const nestedPanel of panel.panels) {
                // inject datasources
                if (nestedPanel?.datasource?.type === 'mysql') {
                  nestedPanel.datasource.uid = dashboardInfos.mysql.uid;
                }
                if (nestedPanel?.datasource?.type === 'influxdb') {
                  nestedPanel.datasource.uid = dashboardInfos.influx.uid;
                }

                // inject beacon
                if (nestedPanel?.targets?.length) {
                  for (const nestedPanelTarget of nestedPanel.targets) {
                    // Replace '$beacon_selection' by the current mac address
                    if (nestedPanelTarget?.query) {
                      /** Inject datasources */
                      nestedPanelTarget.query = nestedPanelTarget.query.replace(
                        '$beacon_selection',
                        dashboardInfos.beacon
                      );
                    }
                  }
                }
              }
            }
          }

          newProfileTemplate.folderId = jsonToUpdate?.meta?.folderId;
          folderId = newProfileTemplate.folderId;
          jsonToUpdate = newProfileTemplate;
        }
      }

      // Get old sensor_orientation
      const indexOrientation = jsonToUpdate.dashboard.panels[advisorIndex].idCardElements.findIndex(
        (element: { field: string }) => element.field === SENSOR_ORIENTATION_TABLE
      );

      if (indexOrientation !== -1 && formOrientationIndex !== -1) {
        let oldOrientation = jsonToUpdate.dashboard.panels[advisorIndex].idCardElements[indexOrientation].value;
        const orientation = form[formOrientationIndex]?.value;
        orientationHasChanged = oldOrientation !== orientation;
      }
    }

    // Update dashboard JSON with new data
    dashboardToUpdate = jsonToUpdate;
    dashboardToUpdate.folderId = folderId;
    dashboardToUpdate.overwrite = true;
    dashboardToUpdate.dashboard.title = dashboardInfos.dashboardName;

    const DASHBOARD_TO_UPDATE = dashboardToUpdate.dashboard;

    /** Fill identity card infos JSON in dashboard object */
    const idCardItems = [] as any;
    FIELDS_JSON_IDCARD.map((field) => {
      const fieldIndex = form.findIndex((formField) => formField.tableName === field);
      idCardItems.push({
        field: `${field}`,
        value: fieldIndex !== -1 && form[fieldIndex]?.value ? form[fieldIndex].value.trim() : '',
      });
    });

    // Add cutom iso even if it's empty
    idCardItems.push({ field: 'customIso', value: [customIso.warning, customIso.alert] });

    /** Search for Incident Advisor plugin to fill in id card elements */
    searchAndFillPanelIdCard(DASHBOARD_TO_UPDATE.panels, idCardItems);

    /** Inject threshold in Vibration Severity alert panel, depending on current power class. */
    injectThresholdInAlertPanel(
      idCardItems,
      DASHBOARD_TO_UPDATE,
      dictionary_uiElements,
      lengthUnit,
      customIso,
      isoClassifications
    );

    /** Inject units (temperature) */
    updateTemperatureUnitPanels(
      DASHBOARD_TO_UPDATE,
      temperatureUnit,
      TR_ambientTemperature,
      TR_surfaceTemperature,
      TR_aliasAmbientTemperature
    );

    /** Inject units (length) */
    updateLengthUnitPanels(DASHBOARD_TO_UPDATE, dashboardInfos.beacon, lengthUnit, TR_vibratorySeverity);

    /** Update grafana templates : 'imageFile', 'X', 'Y', 'Z', 'length_unit' */
    const templatesCopy = getUpdatedTemplatingList(dashboardToUpdate, orientationValues, fileImageName, lengthUnit);
    DASHBOARD_TO_UPDATE.templating = { list: templatesCopy };

    /** Prepare payload */
    const payloadUpdate = preparePayloadAndIdElements(
      form,
      multiLingualDictionary,
      folderId,
      dashboardInfos.beacon,
      lang
    ).payload;
    DASHBOARD_TO_UPDATE.tags = payloadUpdate.tags.split(',');

    /** Update identity_card - Prepare payload */
    await getBackendSrv()
      .put(IDENTITY_CARD_ENDPOINT, payloadUpdate, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        // insertIntoMetaTables(); // These need to be implemented in the next version (add custom entries)

        /** Invalidate last session if orientation / analysis_profile have been changed */
        if (orientationHasChanged || profileHasChanged) {
          const aiSessionStatusQuery = `SELECT id FROM ai_session WHERE mac_address ="${dashboardInfos.beacon}" ORDER BY id DESC LIMIT 1;`;
          const aiSessionIdResults = await mysqlRequest(datasourceMysql.uid, aiSessionStatusQuery).catch((err) => {
            NotificationError(err);
            return;
          });

          if (!aiSessionIdResults) {
            return;
          }

          if (aiSessionIdResults.length) {
            const aiPayload = {
              mac_address: dashboardInfos.beacon,
              ai_session_id: aiSessionIdResults[0][0],
              new_status: STATUS_INVALIDATED,
            };

            await getBackendSrv()
              .put(AI_SESSION_STATUS_ENDPOINT, aiPayload, {
                responseType: 'text',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .catch(() => console.log('Error while invalidating last training session'));
          }
        }
      })
      .then(async () => {
        /** Post updated dashboard to Grafana API */
        await getBackendSrv()
          .post(API_SAVE_JSON, dashboardToUpdate)
          .catch(() => {
            handleError(ErrorTypes.badRequest, TR_errorSavingDashboard);
          });
      })
      .then(async () => {
        if (fileImage !== '#') {
          const appEvents = getAppEvents();

          await sendImage(fileImage, fileImageName, WRITE_API_URL).catch((err: any) => {
            appEvents.publish({
              type: AppEvents.alertError.name,
              payload: [err.status ? `Error status ${err.status}` : 'Error', TR_errorSaveImage],
            });
            handleError(ErrorTypes.badRequest, TR_errorSaveImage);
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
        handleError(ErrorTypes.badRequest, TR_errorRequestDb);
      });

    setIsLoading(false);
  };

  /**
   * CREATE DASHBOARD (in MySQL and in Grafana)
   */

  const createDashboard = async () => {
    setLoadingText(TR_loadingTextCreate);

    const payload = preparePayloadAndIdElements(
      form,
      multiLingualDictionary,
      dashboardFolder.id,
      dashboardInfos.beacon,
      lang
    ).payload;
    let idValues = preparePayloadAndIdElements(
      form,
      multiLingualDictionary,
      dashboardFolder.id,
      dashboardInfos.beacon,
      lang
    ).idValues;

    // Add custom iso class even if it's empty
    idValues['customIso'] = [customIso.warning, customIso.alert];
    dashboardInfos.idCardValues = idValues;

    //Check if user has decided to overwrite values in db (when a dashboard has been deleted in Grafana but not in database)
    const checkRequest = `SELECT machine_name FROM identity_card WHERE mac_address = '${dashboardInfos.beacon}'`;
    const checkMacAddress = await mysqlRequest(datasourceMysql.uid, checkRequest).catch((err) => {
      handleError(ErrorTypes.badRequest, TR_errorRequestDb);
      return;
    });

    // Update dashboard if user wants to overwrite it
    if (checkMacAddress?.length) {
      await getBackendSrv()
        .put(IDENTITY_CARD_ENDPOINT, payload, {
          responseType: 'text',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async () => {
          await sendNewDashboardToGrafana();
          if (fileImage !== '#') {
            await sendImage(fileImage, fileImageName, WRITE_API_URL);
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          handleError(ErrorTypes.badRequest, TR_errorRequestDb);
          setIsLoading(false);
          console.log(e);
        });
      return;
    }

    // let sqlRequest = `${checkMacAddress.length ? 'REPLACE' : 'INSERT'
    //   } INTO identity_card (${fields}) VALUES (${values})`;

    await getBackendSrv()
      .post(IDENTITY_CARD_ENDPOINT, payload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        await sendNewDashboardToGrafana();
        if (fileImage !== '#') {
          await sendImage(fileImage, fileImageName, WRITE_API_URL);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        handleError(ErrorTypes.badRequest, TR_errorRequestDb);
        setIsLoading(false);
        console.log(e);
      });
  };

  /**
   * Reset steps
   */
  const resetSteps = () => {
    dispatch({
      type: 'RESET_STEPS',
      payload: currentModal === Process.update ? CurrentStep.UPDATE_STEP1 : CurrentStep.GENERAL_INFOS,
    });
  };

  useEffect(() => {
    if (currentModal === Process.create) {
      createDashboard();
      return;
    }
    if (currentModal === Process.update) {
      updateDashboard();
      return;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {isLoading && !error.badRequest && <Loading loadingText={loadingText} />}
      {error.badRequest && <ErrorRequest error={error.badRequest} />}
      {!error.badRequest && !isLoading && (
        <FinalMessages
          currentModal={currentModal}
          dashboardName={dashboardInfos.dashboardName}
          messageUpdate={TR_finalMessageUpdate}
          messageUpdate2={TR_finalMessageUpdate2}
          messageCreate={TR_finalMessageCreate}
          messageCreate2={TR_finalMessageCreate2}
        />
      )}
      <div className={`${styles.buttonsContainer} ${styles.buttonGo}`}>
        {!error.badRequest && !isLoading && (
          <div onClick={() => window.open(newDashboardUrl)} className={styles.button}>
            <Icon name="arrow-right" size="xl" />
            {TR_finalButtonGo}
          </div>
        )}
        {!isLoading && (
          <RestartProcessButton
            onClick={resetSteps}
            buttonText={currentModal === Process.create ? TR_finalButtonCreate : TR_finalButtonUpdate}
            modal={currentModal}
          />
        )}
      </div>
    </div>
  );
};

interface LoadingProps {
  loadingText: string;
}
const Loading: React.FunctionComponent<LoadingProps> = ({ loadingText }) => {
  const styles = getStyles();
  return (
    <div className={styles.headerContainer}>
      <Spinner />
      <div className={styles.subtitle}>{loadingText}</div>
    </div>
  );
};

interface ErrorRequestProps {
  error: string;
}
const ErrorRequest: React.FunctionComponent<ErrorRequestProps> = ({ error }) => {
  const styles = getStyles();

  return (
    <div className={styles.headerContainer}>
      <div style={{ color: RED }}>{error}</div>
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      width: 100%;
      height: 100%;
      padding: 45px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
    `,
    buttonsContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    headerContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    title: css`
      font-size: 29px;
      color: #8e8e8e;
      text-align: center;
    `,
    subtitle: css`
      font-size: 18px;
      color: #bababa;
    `,
    button: css`
      min-width: 375px;
      padding: 19px 44px;
      margin-bottom: 25px;
      border: none;
      border-radius: 15px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    `,
    buttonGo: css`
      color: #8e8e8e;
    `,
  };
};
