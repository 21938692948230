import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconName, RadioButtonGroup, Select, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { FormFieldProps } from 'app-context/types';
import { css } from '@emotion/css';
import { SENSOR_ORIENTATION_TABLE } from 'helpers/constants';
import { addToForm, findIndexDictionnary, findIndexInForm, getDefaultValue, getPlaceholder } from 'helpers/helpersForm';
import { RED } from 'helpers/helpersStyle';
import React, { useEffect, useState } from 'react';
import { RedStarRequiredField } from 'shared';

/**
 * Display form elements of Mounting Location information
 */

const DEFAULT_ORIENTATION_VALUES = [
  { name: 'vertical', value: '', icon: 'arrow-down' },
  { name: 'axial', value: '', icon: 'sync' },
];

export const FormField: React.FunctionComponent<FormFieldProps> = ({ name, isRequired, isInvalid, tableName }) => {
  const [radioButtons, setRadioButtons] = useState(DEFAULT_ORIENTATION_VALUES);

  const { state, dispatch } = useAppState();
  const { dropdownMenusOptions, steps, form, dictionary_uiElements } = state;
  const styles = useStyles2(getStyles);

  const indexFieldForm = findIndexInForm(name, form);
  const optionsIndex = findIndexDictionnary(tableName, dropdownMenusOptions);
  const optionsSelect = dropdownMenusOptions[optionsIndex] && dropdownMenusOptions[optionsIndex].values;
  const indexOrientation: number = form.findIndex((el) => el.tableName === SENSOR_ORIENTATION_TABLE);

  useEffect(() => {
    if (tableName === SENSOR_ORIENTATION_TABLE) {
      let radioButtonsDefaultValue: string;

      if (indexOrientation !== -1) {
        radioButtonsDefaultValue = form[indexOrientation].value;
        const finalValues = radioButtonsDefaultValue.split(' ').filter((str) => str !== '' || str !== undefined);

        setRadioButtons([
          { name: 'vertical', value: finalValues[0], icon: 'arrow-down' },
          { name: 'axial', value: finalValues[2], icon: 'sync' },
        ]);
      }
    }
    return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles select TYPE and SENSOR_LOCATION
   */
  const handleChange = (e: any) => {
    // Note : if the value is cleared, e is null
    if (!e) {
      addToForm(dispatch, '', name, tableName);
      return;
    }
    addToForm(dispatch, e.label, name, tableName, e.label);
  };

  // const handleSelectOptionsCreation = (v: string, arrayOptions: any) => {
  //   arrayOptions.push({ label: v, value: v });
  //   addToForm(dispatch, v, name, tableName, v);
  // };

  const handleChangeRadioButtons = (v: any, button: any, buttonIndex: number) => {
    const newState = [...radioButtons];
    newState[buttonIndex] = {
      name: button.name,
      value: v!,
      icon: button.icon,
    };

    setRadioButtons(newState);

    const radioButtonsValues = newState.map((v) => v.value);
    let stringToSend = '';
    const findEmptyString = newState.find((v) => v.value === '');
    if (findEmptyString === undefined) {
      stringToSend = `${radioButtonsValues[0]} ↓ ${radioButtonsValues[1]} ⟳`;
      addToForm(dispatch, stringToSend, name, tableName);
    }
  };

  const getRadioButtons = () => {
    const orientationOptions = [
      { label: 'X', value: 'X' },
      { label: 'Y', value: 'Y' },
      { label: 'Z', value: 'Z' },
      { label: '-', value: '-' },
    ];

    return radioButtons.map((button, buttonIndex) => {
      const stylesButton =
        steps.stepsStatus.step2?.isSubmit && button.value === '' && !form[indexOrientation]
          ? styles.radioButtonInvalid
          : styles.buttonWithIcon;

      return (
        <div className={stylesButton} key={button.name}>
          <Tooltip content={button.name} placement="bottom">
            <Icon name={button.icon as IconName} size="xl" className={styles.icon} />
          </Tooltip>
          <RadioButtonGroup
            options={orientationOptions}
            value={radioButtons[buttonIndex].value}
            size="sm"
            onChange={(v) => handleChangeRadioButtons(v, button, buttonIndex)}
          />
        </div>
      );
    });
  };

  const getInput = () => {
    switch (tableName) {
      /**Sensor_orientation */
      case SENSOR_ORIENTATION_TABLE:
        return <div className={styles.buttonsGroup}>{getRadioButtons()}</div>;
      /** Machine Part, Sensor Location */
      default:
        return (
          <Select
            width={51}
            options={optionsSelect}
            value={getDefaultValue(indexFieldForm, form)}
            onChange={(e) => handleChange(e)}
            placeholder={getPlaceholder(tableName, dictionary_uiElements)}
            invalid={isInvalid}
            // allowCustomValue
            isSearchable
            isClearable
            // onCreateOption={(v) => handleSelectOptionsCreation(v, optionsSelect)}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {name} {isRequired && <RedStarRequiredField />}
      </div>
      <div className={styles.inputContainer}>{getInput()}</div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      max-width: 635px;
      min-width: 300px;
      height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto 15px auto;
    `,
    label: css`
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.text};
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      margin-right: 10px;
      max-width: 250px;
      min-width: 225px;
      padding: 7px;
      text-align: left;
      text-justify: center;
      width: 30%;
    `,
    macAdressContainer: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    `,
    numbers: css`
      margin-right: 10px;
    `,
    inputContainer: css`
      width: 100%;
    `,
    buttonsGroup: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
    `,
    buttonWithIcon: css`
      display: flex;
      flex-direction: row;
      padding: 4px;
      border-radius: 5px;
      border: 1px solid rgba(237, 241, 241, 0);
    `,
    radioButtonInvalid: css`
      display: flex;
      flex-direction: row;
      background-color: #f7ffff;
      padding: 4px;
      border: 1px solid ${RED};
    `,
    icon: css`
      margin-right: 8px;
    `,
  };
};
