import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { TranslationProps } from 'app-context/translation-types';

export interface AppStateContextProps {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export type Action =
  | {
      type: 'TOGGLE_VISIBLE';
      payload: ModalProps;
    }
  | {
      type: 'STEP_FORWARD';
      payload: number;
    }
  | {
      type: 'STEP_BACKWARD';
      payload: number;
    }
  | {
      type: 'INITIAL_STEP';
      payload: number;
    }
  | {
      type: 'ADD_TO_FORM';
      payload: {
        fieldName: string;
        label?: string;
        tableName: string;
        value: any;
      };
    }
  | {
      type: 'RESET_STEPS';
      payload: number;
    }
  | {
      type: 'ADD_IMAGE_FILE';
      payload: File | string;
    }
  | {
      type: 'ADD_LANG';
      payload: string;
    }
  | {
      type: 'SET_ERROR';
      payload: {
        errorName: string;
        text: string;
      };
    }
  | {
      type: 'IS_VALIDATED';
      payload: {
        name: string;
        step: string;
        value: boolean;
      };
    }
  | {
      type: 'IS_SUBMITTED';
      payload: {
        value: boolean;
        step: string;
      };
    }
  | {
      type: 'ADD_SELECT_OPTIONS';
      payload: {
        tableName: string;
        value: Option[];
      };
    }
  | {
      type: 'REMOVE_SELECT_OPTIONS';
    }
  | {
      type: 'ADD_FILE_NAME';
      payload: string;
    }
  | {
      type: 'ADD_UID';
      payload: string;
    }
  | {
      type: 'IS_DATA_LOADED';
      payload: boolean;
    }
  | {
      type: 'ADD_DATASOURCES';
      payload: {
        influx: any;
        mysql: any;
        json: any;
      };
    }
  | {
      type: 'ADD_OPTIONS_DICO';
      payload: any;
    }
  | {
      type: 'ADD_TRANSLATED_UI_TO_DICO';
      payload: TranslationProps;
    }
  | {
      type: 'SET_DASHBOARDS_LIST';
      payload: DashboardListProps[];
    }
  | {
      type: 'ADD_DASHBOARD_FOLDER';
      payload: FolderProps;
    }
  | {
      type: 'SET_MAC_ADDRESSES';
      payload: MacAddressProps[];
    }
  | {
      type: 'SET_SELECTED_FOLDER';
      payload: SelectableValue;
    }
  | {
      type: 'SET_TEMPERATURE_UNIT';
      payload: string;
    }
  | {
      type: 'SET_LENGTH_UNIT';
      payload: string;
    }
  | {
      type: 'SET_CUSTOM_ISO';
      payload: {
        alert: string;
        warning: string;
      };
    }
  | {
      type: 'SET_ISO_CLASSIFICATIONS';
      payload: IsoClassificationsProps;
    };
export interface FormFieldProps {
  isInvalid?: boolean;
  isRequired: boolean;
  isSubmit?: boolean | undefined;
  name: string;
  tableName: string;
}

export interface FolderProps {
  name: string;
  id: number;
}

export interface MachineFilterProps {
  name: string;
  id: string;
  isEditing: boolean;
  isAddingFilter?: boolean;
  isFilterNameModified: boolean;
  folderId: number;
}

export interface MacAddressProps {
  macAddress: string;
  folderId: number;
  machineFilterId: string;
  dashboardName: string;
  hasBeenMoved: boolean;
  dashboardUid: string;
  otherTags: Option[];
  isTagsModified: boolean;
}

export interface DicoProps {
  name: string;
  values: Option[];
}

export interface Option {
  label: string;
  value: string | number;
}

export enum Process {
  'create',
  'update',
  'delete',
  'manage_single_pages',
  none = '',
}

export interface ModalProps {
  process: Process;
  isOpen: boolean;
}

export interface StepStatusProps {
  isSubmit: boolean;
  isValid?: boolean;
}

export interface FormProps {
  fieldName: string; //form field, client side (translated if needed)
  isValidate: boolean;
  label?: string;
  tableName: string; //field, server side
  value: string | number | any;
}

export interface UserProps {
  isGrafanaAdmin: boolean;
  orgId: number;
  orgName: string;
  orgRole: string;
}

export interface AppState {
  currentModal: Process;
  dashboardsList: DashboardListProps[];
  dashboardFolder: FolderProps;
  datasourceInflux: any;
  datasourceJson: any;
  datasourceMysql: any;
  dictionary_uiElements: TranslationProps;
  dropdownMenusOptions: DicoProps[];
  error: ErrorProps;
  fileImage: string | File;
  fileImageName: string;
  form: FormProps[];
  isDataLoaded: boolean;
  lang: string;
  macAddresses: MacAddressProps[];
  modals: ModalProps[];
  multiLingualDictionary: any;
  selectedFolder: SelectableValue;
  steps: {
    current: number;
    stepsStatus: {
      step99?: StepStatusProps; // First and unique step of deletion process
      step98?: StepStatusProps; // first and unique step of manage single pages
      step0?: StepStatusProps; // First step of Update
      step1?: StepStatusProps; // First step of creation
      step2?: StepStatusProps; // Next step of update or creation
      step3?: StepStatusProps; //
      step4?: StepStatusProps; // Summary step of update or creation
      step5?: StepStatusProps; // Final step of update or creation
    };
  };
  theme: GrafanaTheme2;
  uid: string;
  user: UserProps;
  temperatureUnit: string;
  lengthUnit: string;
  customIso: { warning: string; alert: string };
  isoClassifications: IsoClassificationsProps;
}

export interface DashboardProps {
  dashboard: {
    title: string;
    tags: string[];
    templating: {
      list: any[];
    };
    panels: any[];
    idCardElements: any[];
  };
  overwrite: boolean;
  folderId: number;
}

export interface DashboardListProps {
  folderId?: number;
  label?: string;
  macAddress?: string;
  name: string;
  value: any;
  tags: string[];
}

export interface DashboardInfos {
  beacon: string;
  dashboardName: string;
  fileImageName: string;
  idCardValues: any;
  influx: DatasourceDetails;
  mysql: DatasourceDetails;
  lang: string;
  style: string;
  tags: string[];
  X: string;
  Y: string;
  Z: string;
  customIso: { warning: string; alert: string };
}

interface DatasourceDetails {
  type: 'mysql' | 'influxdb';
  uid: string;
}

interface ObjOrientation {
  value: string;
  increment: number;
}

export interface Orientation {
  X: ObjOrientation;
  Y: ObjOrientation;
  Z: ObjOrientation;
}

export enum ErrorTypes {
  badRequest = 'badRequest',
  dashboardName = 'name',
  datasources = 'datasource',
  macAddress = 'macAddress',
  macAddressDashboardDeleted = 'macAddressDashboardDeleted',
  noDashboardFound = 'noDashboardFound',
  macAddressPrefix = 'macAddressPrefix',
  customThresholds = 'customThresholds',
}

export type ErrorProps = { [Key in ErrorTypes]: string };

export enum CurrentStep {
  DELETE_STEP1 = 99,
  MANAGE_SINGLE_PAGES_STEP1 = 98,
  UPDATE_STEP1 = 0,
  GENERAL_INFOS = 1,
  MOUNTING_LOCATION = 2,
  ANALYSIS_PROFILE = 3,
  SUMMARY = 4,
  VALIDATION = 5,
}

export enum TemplatesVar {
  beacon = 'beacon_selection',
  image = 'imageFile',
  lang = 'lang_selection',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  lengthUnit = 'length_unit',
}

export enum RelatedPluginTypes {
  ia = 'incident-advisor',
  sd = 'synthetic-dashboard',
}

export interface TabsProps {
  label: string;
  active: boolean;
  id: number;
}

export enum DashboardTemplateType {
  comparison = 'comparison-dashboard',
  main = 'main-dashboard',
  overview = 'synthetic-dashboard',
}

export interface DashboardReceived {
  id: number | null;
  isStarred: boolean;
  lang: string;
  slug: string;
  tags: string[];
  templatesVariables: any[];
  title: string;
  type: string;
  uid: string;
  uri: string;
  url: string;
}

export interface ThresholdValues {
  alarm: number;
  trip: number;
}

export enum IsoClasses {
  class1 = 'class1',
  class2 = 'class2',
  class3 = 'class3',
  class4 = 'class4',
  a1 = 'a1',
  a2 = 'a2',
  a3 = 'a3',
  a4 = 'a4',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  default = 'default',
}

export enum TranslationLang {
  EN = 'EN',
  FR = 'FR',
}

export type IsoClassTypes = { [Key in IsoClasses]: ThresholdValues };
export type IsoClassTranslationTypes = { [Key in IsoClasses]: string };
export type TranslationsTypes = { [Key in TranslationLang]: IsoClassTranslationTypes };

export interface IsoClassificationsProps {
  vibrationSeverityIsoMms: IsoClassTypes;
  vibrationSeverityIsoIns: IsoClassTypes;
  translations: TranslationsTypes;
}

export interface PayloadProps {
  mac_address: string;
  process_function: string;
  machine_name: string;
  org_id: number;
  machine_class_longname: string;
  analysis_profile: string;
  orientation: string;
  tags: string;
  sensor_location?: string;
  manufacturer?: string;
  model?: string;
  type?: string;
  [key: string]: string | undefined | number;
}
