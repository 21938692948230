import { getBackendSrv } from '@grafana/runtime';

const API_MYSQL_REQUEST = 'api/ds/query';

export const mysqlRequest = async (datasourceUid: string, query: string): Promise<any[]> => {
  const mysqlResults = await getBackendSrv().post(API_MYSQL_REQUEST, {
    queries: [
      {
        datasource: {
          type: 'mysql',
          uid: datasourceUid,
        },
        format: 'table',
        rawSql: query,
        refId: 'tempvar',
      },
    ],
  });

  const results = mysqlResults.results.tempvar.frames;
  if (results.length) {
    const resultsArrays = results[0].data.values;
    const finalresult = resultsArrays[0].map(() => new Array(resultsArrays.length));
    for (const [indexArray, array] of resultsArrays.entries()) {
      for (const [indexValue, value] of array.entries()) {
        finalresult[indexValue][indexArray] = value;
      }
    }
    return finalresult;
  } else {
    return [];
  }
};
