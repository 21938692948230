import { thumbnail } from './resizeImage';
import { getBackendSrv } from '@grafana/runtime';
import { FormProps, PayloadProps } from 'app-context/types';
import {
  CLASS_TABLE,
  MACHINE_TAG,
  MAC_ADDRESS_TABLE,
  SENSOR_ORIENTATION_TABLE,
  TABLENAMES_DB,
  TAGS_TABLE,
} from './constants';
import { hasValueToBeTranslated } from './helpersForm';

/** Post image to node-red */
export const sendImage = async (fileImage: string | File, fileImageName: string, url: string) => {
  const resizedImage = thumbnail(fileImage);
  const options = {
    file: resizedImage,
    fileName: fileImageName,
  };

  await getBackendSrv().post(`${url}/uploadImage`, options);
};

export const preparePayloadAndIdElements = (
  form: FormProps[],
  multiLingualDictionary: any,
  folderId: number,
  macAddress: string,
  lang: string
) => {
  const searchMachineTag = (tag: { value: string }) => tag.value === MACHINE_TAG;

  const payload = {} as PayloadProps;
  let idValues = {} as any; // to be stored in grafana dashboard as default values of Id Card

  for (const tableName of TABLENAMES_DB) {
    const fieldIndex = form.findIndex((formField) => formField.tableName === tableName);

    if (tableName === TAGS_TABLE && fieldIndex === -1) {
      payload.tags = MACHINE_TAG;
      continue;
    }

    if (form[fieldIndex]?.value) {
      // handle tags
      if (tableName === TAGS_TABLE) {
        let valuesTags = form[fieldIndex]?.value.map((tag: any) => tag.label);
        const findMachineTag = form[fieldIndex]?.value.findIndex(searchMachineTag);
        if (findMachineTag === -1) {
          valuesTags.push(MACHINE_TAG);
        }
        payload.tags = valuesTags.join();
        continue;
      }

      if (tableName === MAC_ADDRESS_TABLE) {
        idValues[tableName] = macAddress;
        payload.mac_address = macAddress;
        continue;
      }

      if (tableName === SENSOR_ORIENTATION_TABLE) {
        idValues[tableName] = form[fieldIndex]?.value;
        payload.orientation = form[fieldIndex]?.value;
        continue;
      }

      const HAS_TO_BE_TRANSLATED = hasValueToBeTranslated(tableName);

      if (lang !== 'EN' && HAS_TO_BE_TRANSLATED) {
        for (const [dicoKey] of Object.entries(multiLingualDictionary[tableName] as any) as any) {
          if (multiLingualDictionary[tableName][dicoKey] === form[fieldIndex]?.value) {
            idValues[tableName] = form[fieldIndex]?.value;

            if (tableName === CLASS_TABLE) {
              payload.machine_class_longname = dicoKey;
              continue;
            }
            payload[tableName] = dicoKey;
            continue;
          }
          idValues[tableName] = form[fieldIndex]?.value;
          payload[tableName] = form[fieldIndex]?.value;
          continue;
        }
      }

      if (tableName === CLASS_TABLE) {
        idValues[tableName] = form[fieldIndex]?.value;
        payload.machine_class_longname = form[fieldIndex]?.value;
        continue;
      }
      idValues[tableName] = form[fieldIndex]?.value;
      payload[tableName] = form[fieldIndex]?.value;
      continue;
    }
  }

  // Add folder id
  payload.org_id = folderId;

  return { payload, idValues };
};
